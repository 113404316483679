.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.title {
  margin-top: 5%;
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #FFFFFF;
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
}
