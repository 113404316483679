html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #8a32fd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
