.piece {
    width: 50px;
    height: 50px;
    border: 3px solid #000000;
    border-radius: 50%;
    z-index: 2;
    margin: 4px;
    pointer-events: none;
}

.player1 {
    background-color: 'lightblue';
}

.player2 {
    background-color: 'darkblue';
}

