.board{
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #ffffff;
    display: flex;
}

.boardHalf{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    flex-direction: column;
  }

  .boardQuarter{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
  }

  .centre{
    display: flex;
    flex-direction: column;
    width: 60px;
  }

  .bar{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60px;
    height: 640px;
    background-color: #659b4e;
  }

  .diceContainer {
    position: absolute; /* Position relative to board-container */
    top: 550px; /* Adjust to place the dice on the board */
    left: 29.5%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
    display: flex;
    gap: 10px; /* Space between the dice */
    cursor: pointer; /* Make the dice clickable */
    z-index: 2;
  }

  /* Each dice */
  .dice {
    width: 100px;
    height: 100px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6rem;
    border-radius: 5px;
    background-color: transparent;
  }