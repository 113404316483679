.triangleContainer {
  display: flex;
  align-items: center;
  width: 120px;
  height: 300px;
}


.triangle {
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    z-index: 1;
    position: absolute;
}
